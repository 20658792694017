<template>
  <Frame :loading="loading">
    <Grid
      :actionConditional="actions"
      :data="data"
      :paginated="false"
      @addNew="addNew"
      @reloadData="getUsers"
      addText="Agregar usuario"
      class="custom-config-grid"
      customPlaceholder="Buscar usuario"
    >
      <template slot-scope="{ row }" tag="tr">
        <GridColumn :searchable="true" field="userName" title="Usuario">
          {{ row.userName }}
        </GridColumn>
        <GridColumn :searchable="true" field="roleName" title="Rol">
          {{ row.roleName }}
        </GridColumn>
        <GridColumn title="U. negocio">
          <span> {{ row.businessUnitTop }} </span>
          <span
            class="outlined"
            v-if="row.businessUnits.length > 1 && row.businessUnitTop !== 'Todas'"
          >
            {{ '+' + (row.businessUnits.length - 1) }}
            <!--            <span class="tooltip">-->
            <!--              <span-->
            <!--                v-for="(obj, index) in row.businessUnits.slice(0, -1)"-->
            <!--                :key="index"-->
            <!--                v-html="`${obj.description} <br>`"-->
            <!--              />-->
            <!--            </span>-->
          </span>
        </GridColumn>
        <GridColumn title="Estado">
          <span class="pw-tag" :class="`is-${row.locked ? 'terciary' : 'color3'}`">
            {{ row.locked ? 'Bloqueado' : 'Activo' }}
          </span>
        </GridColumn>
        <GridColumn title="Permisos">
          {{ row.claimsCount }}
        </GridColumn>
      </template>
    </Grid>
    <Modal :active.sync="openModal" class="modal-with-overflow">
      <ConfigUserForm :modelToEdit="modelToEdit" :readonly="readonly" />
    </Modal>
  </Frame>
</template>

<script>
import ConfigUserForm from './ConfigUserForm.vue';

export default {
  name: 'Users',
  components: {
    ConfigUserForm,
  },
  data() {
    return {
      data: [],
      openModal: false,
      modelToEdit: null,
      loading: false,
      readonly: false,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    actions(item) {
      const options = [
        {
          text: 'Ver',
          callback: this.onView,
        },
        {
          text: 'Editar',
          callback: this.onEdit,
        },
        {
          text: 'Desbloquear usuario',
          callback: this.unlockUser,
        },
        {
          text: 'Eliminar',
          callback: this.deleteUser,
        },
      ];

      if (!item.locked) {
        options.splice(2, 1);
      }
      return options;
    },
    getUsers() {
      this.loading = true;

      this.getAll('Configuration/Users', '', 'stamp').then((data) => {
        this.data = data;
        this.loading = false;
      });
    },
    deleteUser(item) {
      this.deleteItem(
        'Configuration/User',
        item.userID,
        '¿Deseas eliminar el usuario?',
        'Al eliminar el usuario este perderá el acceso a todos los módulos y aplicaciones de Peopleware.',
        'stamp'
      );
    },
    onView(item) {
      this.readonly = true;
      this.getModel(item);
    },
    onEdit(item) {
      this.readonly = false;
      this.getModel(item);
    },
    unlockUser(item) {
      this.showConfirm(
        '¿Desea desbloquear el usuario?',
        'Al desbloquear el usuario, éste recuperará su acceso a Timbrado nuevamente',
        'information',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: {
              color: 'terciary-outlined',
            },
          },
          {
            text: 'Desbloquear',
            callback: () =>
              this.$post(`Configuration/unlockUser/${item.userID}`, {}, 'stamp').then(
                ({ data }) => {
                  if (data) {
                    this.setNotification(data.operationMessage, 'success', 'Usuario desbloqueado');
                    this.$root.$emit('reloadData');
                  }
                }
              ),
            props: {
              color: 'secondary',
            },
          },
        ]
      );
    },
    getModel(item) {
      this.modelToEdit = {
        UserID: item.userID,
        UserName: item.userName,
        FirstName: item.firstName,
        MiddleName: item.middleName,
        LastName1: item.lastName1,
        LastName2: item.lastName2,
        Password: item.password,
        UserIntegration: item.userIntegration,
        Gender: item.genderCode,
        RoleID: item.roleID || null,
        BusinessUnits: item.businessUnits, // this.getBusinessUnitIDs(item.businessUnits),
        AccessToAllLocations: item.accessToAllLocations || false,
      };
      this.openModal = true;
    },
    // getBusinessUnitIDs(list) {
    //   return list.map(loc => {
    //     return loc.id
    //   })
    // },
    addNew() {
      this.readonly = false;
      this.modelToEdit = null;
      this.openModal = true;
    },
  },
};
</script>

<style lang="scss">
// .custom-tab-strip {
//   .tab-strip__items {
//     margin: 0;
//   }
//   &.pw-tab-strip--is-vertical.pw-tab-strip > .pw-tab-strip__header {
//     width: 33.333% !important;
//     li {
//       font-size: 14px;
//     }
//   }
// }
.outlined {
  position: relative;
  margin: 4px;
  padding-left: 8px;
  padding-right: 8px;
  color: #707070;
  border: solid #707070 1px;
  border-radius: 10px;
}

.filter__table {
  background-color: #fff;
  padding: 2px;

  i {
    font-size: 150%;
  }
}

.custom-config-grid {
  .table-actions {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .pw-datagrid {
    &__head {
      th {
        background-color: #fbfbfb;
      }
    }
    &__body {
      .pw-datagrid__row {
        td {
          background-color: #fff;
        }
      }
    }
  }
}

/* Tooltip text */
.tooltip {
  visibility: hidden;
  width: 380px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/*.tooltip {
    bottom: 115%;
    margin-left: -340px;
  }*/

.outlined .tooltip {
  bottom: 150%;
  margin-left: -200px;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.outlined:hover {
  .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.modal-with-overflow {
  .pw_modal__modal-content {
    overflow-y: scroll;
  }
}
</style>
