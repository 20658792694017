<template>
  <div>
    <Frame title="Configuración" titleAction="Usuarios">
      <Title
        description="Indica la información personal del usuario y las credenciales con las que accederá a Peopleware."
        title="Datos generales"
      />
      <Layout>
        <Column size="4">
          <Field label="Primer nombre">
            <Input
              :readonly="readOnly"
              :required="true"
              name="FirstName"
              rules="required"
              v-model="model.FirstName"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Segundo nombre">
            <Input :readonly="readOnly" name="MiddleName" v-model="model.MiddleName" />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Primer apellido">
            <Input
              :readonly="readOnly"
              :required="true"
              name="LastName1"
              rules="required"
              v-model="model.LastName1"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Segundo apellido">
            <Input :readonly="readOnly" name="LastName2" v-model="model.LastName2" />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Correo electrónico">
            <Input
              :aria-autocomplete="false"
              :readonly="modelToEdit !== null"
              :required="true"
              name="Mail"
              rules="required"
              v-model="model.UserName"
            />
          </Field>
        </Column>
        <Column size="4" v-if="modelToEdit === null">
          <Field label="Contraseña">
            <!-- Corregir autocomplete -->
            <Input
              :readonly="modelToEdit !== null"
              :required="true"
              :showPasswordToggler="true"
              autocomplete="new-password"
              name="Password"
              rules="required"
              type="password"
              v-model="model.Password"
            />
          </Field>
        </Column>
        <Column size="4" v-if="modelToEdit !== null" />
        <Column size="4">
          <Field label="Usuario de integración">
            <Input :readonly="readOnly" v-model="model.UserIntegration" />
          </Field>
        </Column>
        <!--        <Column size="4" />-->
        <Column size="4">
          <Field label="Género">
            <RadioButtons
              :horizontal="true"
              :options="[
                { label: 'Femenino', value: 'F' },
                { label: 'Masculino', value: 'M' },
              ]"
              :readonly="readOnly"
              :required="true"
              name="gender"
              rules="required"
              v-model="model.Gender"
            />
          </Field>
        </Column>
      </Layout>

      <Title
        description="Asigna el rol que tendrá el usuario dentro de la aplicación y las unidades de negocio que podrá gestionar. Recuerda que el rol definirá los permisos que tendrá el usuario dentro de Peopleware."
        title="Asignación de permisos"
      />
      <Layout>
        <Column size="4">
          <Field label="Rol">
            <MultiSelect :disabled="readOnly" :options="roles" name="role" v-model="model.RoleID" />
          </Field>
        </Column>
        <Column size="8">
          <Field label="Unidades de negocio asignadas">
            <MultiSelect
              :clearable="false"
              :disabled="readOnly || model.AccessToAllLocations"
              :multiple="true"
              :options="businessUnits"
              name="businessUnits"
              v-model="model.BusinessUnits"
            />
          </Field>
        </Column>
        <Column align="right" size="12">
          <Field :horizontal="false" label="Acceso a todas las unidades de negocio">
            <CheckBox :readonly="readOnly" is-switch v-model="model.AccessToAllLocations" xl />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column size="3">
          <Button @click="onDelete()" color="quinary-outlined" v-if="readOnly"> Eliminar </Button>
        </Column>
        <Column align="right" size="9">
          <Button
            @click="readonly && !readOnly ? onCancelEdit() : $parent.close()"
            color="terciary-outlined"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            :color="readOnly ? 'secondary-outlined' : 'secondary'"
            @click="readOnly ? onEdit() : onSave()"
          >
            {{ readOnly ? 'Editar' : modelToEdit ? 'Actualizar' : 'Guardar' }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'ConfigUserForm',
  props: {
    modelToEdit: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        UserName: null,
        RoleID: null,
        BusinessUnits: [],
        AccessToAllLocations: false,
        UserIntegration: null,
      },
      roles: [],
      businessUnits: [],
      readOnly: false,
    };
  },
  mounted() {
    this.readOnly = this.readonly;
    if (this.readonly) {
      this.$root.$emit('changeModeFrame', 'view');
    }

    if (this.modelToEdit) {
      this.titleAction = 'Ver';
      this.model = { ...this.modelToEdit };
    }
    this.getCatalogs();
  },
  methods: {
    getCatalogs() {
      this.getAll('Configuration/UserCatalog', '', 'stamp').then((data) => {
        this.roles = data.rolesCatalog;
        this.businessUnits = data.locationsCatalog;
      });
    },
    onDelete() {
      this.$parent.$parent.$parent.deleteUser({ userID: this.model.UserID }, () => {
        this.$parent.close();
      });
    },
    onEdit() {
      this.readOnly = false;
      this.titleAction = 'Editar';
      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.titleAction = 'Ver';
      this.model = JSON.parse(JSON.stringify(this.modelToEdit));

      this.$root.$emit('changeModeFrame', 'view');
    },
    onSave() {
      this.save('Configuration/User', this.model, 'stamp', 'UserID').then((data) => {
        if (data) {
          this.$parent.close();
        }
      });
    },
  },
};
</script>
