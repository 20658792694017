<template>
  <div>
    <Frame title="Configuración">
      <TabStrip :isVertical="true" class="custom-tab-strip" :setSelected="0">
        <!--$route.query.p ? 1 : 0-->
        <!--        <TabStripItem title="General"/>-->
        <TabStripItem title="Roles y permisos">
          <TabStrip :setSelected="$route.query.p ? 1 : 0">
            <TabStripItem title="Usuarios">
              <Users />
            </TabStripItem>
            <TabStripItem title="Roles">
              <Roles />
            </TabStripItem>
          </TabStrip>
        </TabStripItem>
        <!--        <TabStripItem title="Apariencia"/>-->
        <TabStripItem title="Integraciones">
          <Integrations />
        </TabStripItem>
      </TabStrip>
    </Frame>
  </div>
</template>

<script>
import Users from './ConfigUsers.vue';
import Roles from './ConfigRoles.vue';
import Integrations from '../integrations/Integrations.vue';

export default {
  name: 'Config',
  components: {
    Users,
    Roles,
    Integrations,
  },
};
</script>
