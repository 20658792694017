<template>
  <Frame :loading="loading">
    <Grid
      :data="rolesData"
      :actions="actions"
      customPlaceholder="Buscar rol"
      customEmptyText="Aún no ha sido creado ningún rol, ¡agrega una!"
      addText="Agregar rol"
      :paginated="false"
      class="custom-config-roles-grid"
      :class="{ hidden: !rolesData.length }"
      @addNew="addNew"
      @reloadData="getRoles"
    >
      <template slot-scope="{ row }">
        <GridColumn title="Rol" :searchable="true" field="roleName">
          {{ row.roleName }}
        </GridColumn>
        <GridColumn title="Descripción" :searchable="true" field="description">
          {{ row.description }}
        </GridColumn>
        <GridColumn title="Sistema">
          {{ row.isBySystem ? 'S' : 'N' }}
        </GridColumn>
        <GridColumn title="Usuarios" :width="20">
          {{ row.usersCount }}
        </GridColumn>
        <GridColumn title="Permisos">
          {{ row.claimsCount }}
        </GridColumn>
      </template>
    </Grid>
    <Modal :active.sync="openModal" class="modal-with-overflow">
      <ConfigRoleForm :modelToEdit="modelToEdit" />
    </Modal>
  </Frame>
</template>

<script>
import ConfigRoleForm from './ConfigRoleForm.vue';

export default {
  name: 'Roles',
  components: {
    ConfigRoleForm,
  },
  data() {
    return {
      rolesData: [],
      openModal: false,
      modelToEdit: null,
      actions: [
        {
          text: 'Ver permisos',
          callback: this.onView,
        },
        {
          text: 'Editar descripción',
          callback: this.onEdit,
        },
        // {
        //   text: 'Eliminar',
        //   callback: () => {}
        // }
      ],
      columns: [
        {
          title: 'Nombre del rol',
          field: 'roleName',
        },
        {
          title: 'Descripción',
          field: 'description',
        },
        {
          title: 'Sistema',
          template: () => `<CheckBox />`,
        },
      ],
      loading: false,
      readonly: false,
    };
  },
  mounted() {
    if (this.$route.query.p) this.$router.replace({ query: {} });

    this.getRoles();
  },
  methods: {
    getRoles() {
      this.loading = true;

      this.getAll('Configuration/Roles', '', 'stamp').then((data) => {
        this.rolesData = data;
        this.loading = false;
      });
    },
    onView(item) {
      if (!this.$route.query.p) this.$router.replace({ query: { p: 'roles' } });
      this.$router.push({
        name: 'viewRole',
        params: {
          id: item.roleID,
        },
      });
    },
    onEdit(item) {
      this.readonly = false;
      this.modelToEdit = {
        RoleID: item.roleID,
        RoleName: item.roleName,
        Description: item.description,
      };
      this.openModal = true;
    },
    addNew() {
      this.readonly = false;
      this.modelToEdit = null;
      if (!this.$route.query.p) this.$router.replace({ query: { p: 'roles' } });
      this.$router.push({
        name: 'configAddRole',
      });
    },
    onSave() {
      // model
      // console.log(model);
      this.rolesData.push({
        user: 'david@proaconsulting.com',
        role: 'Nominista',
        pkRole: 1,
        businessUnits: [29, 109],
        businessUnitFirst: '0038 - Grand Velas Riviera Nayarit',
        claimsCount: 8,
      });
    },
  },
};
</script>

<style lang="scss">
.outlined {
  margin: 4px;
  padding-left: 8px;
  padding-right: 8px;
  color: #707070;
  border: solid #707070 1px;
  border-radius: 10px;
}
.filter__table {
  background-color: #fff;
  padding: 2px;
  i {
    font-size: 150%;
  }
}
.custom-config-roles-grid {
  .table-actions {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .pw-datagrid {
    &__head {
      th:nth-last-child(4),
      th:nth-last-child(3),
      th:nth-last-child(2) {
        width: 10%;
      }
      th {
        background-color: #fbfbfb;
      }
    }
    &__body {
      .pw-datagrid__row {
        td {
          background-color: #fff;
        }
      }
    }
  }
  &.hidden {
    table {
      display: none;
      block-size: 0px;
      // height: 0px;
      // visibility: hidden;
    }
    .table-actions {
      .filter__table {
        display: none;
        block-size: 0px;
      }
    }
  }
}
</style>
