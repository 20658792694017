<template>
  <div>
    <Frame title="Integraciones" :loading="loading">
      <Grid :data="data" :columns="columns" addText="Agregar configuración"> </Grid>
    </Frame>
  </div>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';

export default {
  name: 'Integrations',
  data() {
    return {
      data: [],
      loading: false,
      columns: [
        {
          title: 'Producto',
          field: 'productVersion',
        },
        {
          title: 'Versión',
          field: 'version',
        },
        {
          title: 'Tipo de base de datos',
          field: 'databaseType',
        },
      ],
    };
  },
  mounted() {
    this.loading = true;
    const apiClient = new ApiClient('Integrations/CatalogConfiguration', 'stamp');

    apiClient.Get('?query=a').then((data) => {
      this.loading = false;
      this.data = data;
    });
  },
};
</script>
