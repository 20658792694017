<template>
  <div>
    <Frame title="Configuración" titleAction="Roles">
      <Layout>
        <Column size="6">
          <Field label="Nombre del rol">
            <Input
              :required="true"
              :readonly="readOnly"
              name="FirstName"
              v-model="model.RoleName"
              rules="required"
              @keydown.enter="onSave"
            />
          </Field>
        </Column>
        <Column size="12">
          <Field label="Descripción">
            <Input
              :required="true"
              :readonly="readOnly"
              name="MiddleName"
              v-model="model.Description"
              rules="required"
              @keydown.enter="onSave"
            />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button
            @click="readonly && !readOnly ? onCancelEdit() : $parent.close()"
            color="terciary-outlined"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            :color="readOnly ? 'secondary-outlined' : 'secondary'"
            @click="readOnly ? onEdit() : onSave()"
          >
            {{ readOnly ? 'Editar' : modelToEdit ? 'Actualizar' : 'Guardar' }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'ConfigRoleForm',
  props: {
    modelToEdit: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        RoleName: null,
        Description: null,
      },
      readOnly: false,
    };
  },
  mounted() {
    this.readOnly = this.readonly;
    if (this.readonly) {
      this.$root.$emit('changeModeFrame', 'view');
    }

    if (this.modelToEdit) {
      this.titleAction = 'Ver';
      this.model = { ...this.modelToEdit };
    }
  },
  methods: {
    onEdit() {
      this.readOnly = false;
      this.titleAction = 'Editar';
      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.titleAction = 'Ver';
      this.model = JSON.parse(JSON.stringify(this.modelToEdit));

      this.$root.$emit('changeModeFrame', 'view');
    },
    onSave() {
      this.save('Configuration/Role', this.model, 'stamp', 'RoleID').then((data) => {
        if (data) {
          this.$parent.close();
        }
      });
    },
  },
};
</script>
